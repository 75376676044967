<template>
  <div>
    <v-card :elevation="elevation">
      <v-card-title
        class="pa-0 ma-0"
        v-if="showHeader"
      >
        <v-row>
          <v-col
            class="pt-3 mb-3 ma-0"
            cols="12"
          >
            <div
              :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
              class="card-header-tab card-header"
            >
              <div
                class="card-header-title font-size-lg text-capitalize font-weight-normal"
              >
                <b-input-group
                  width="100%"
                  v-if="showSearchField"
                >
                  <b-form-input
                    :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :placeholder="$t('datatables.lang_search')"
                    @focus="showTouchKeyboard"
                    v-model="search"
                  />
                </b-input-group>
              </div>
              <div
                v-if="!this.hideOptionsMenu"
                class="btn-actions-pane-right actions-icon-btn"
              >
                <b-dropdown
                  no-caret
                  right
                  toggle-class="btn-icon btn-icon-only"
                  :menu-class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                  variant="link"
                >
                  <span slot="button-content"
                    ><i class="pe-7s-menu btn-icon-wrapper"></i
                  ></span>
                  <div>
                    <h6
                      class="dropdown-header"
                      tabindex="-1"
                    >
                      {{ $t('generic.lang_Options') }}
                    </h6>
                    <button
                      @click="generatePDF"
                      :class="[this.$vuetify.theme.dark ? 'white--text' : '']"
                      class="dropdown-item"
                      tabindex="0"
                      type="button"
                      v-if="showPdf"
                    >
                      <v-icon
                        class="dropdown-icon"
                        color="warning"
                        >picture_as_pdf
                      </v-icon>
                      <span>PDF</span>
                    </button>

                    <button
                      @click="exportSelectedToPdf"
                      :class="[this.$vuetify.theme.dark ? 'white--text' : '']"
                      class="dropdown-item"
                      tabindex="0"
                      type="button"
                      :disabled="selectedRows.length < 1 || pdfSelectedLoading"
                      v-if="showSelectedPdf"
                    >
                      <template v-if="!pdfSelectedLoading">
                        <v-icon
                          class="dropdown-icon"
                          color="warning"
                          >picture_as_pdf
                        </v-icon>
                      </template>
                      <v-progress-circular
                        v-else
                        class="dropdown-icon"
                        indeterminate
                        color="warning"
                      />
                      <span>{{ $t('generic.lang_PDFSelected') }}</span>
                    </button>

                    <button
                      :disabled="
                        selectedRows &&
                        selectedRows.length === 0 &&
                        (!control.hasOwnProperty('forceEnable') ||
                          !control.forceEnable)
                      "
                      :key="i"
                      @click="customControl(control.handler)"
                      class="dropdown-item"
                      tabindex="0"
                      type="button"
                      v-for="(control, i) in customControlButtons"
                    >
                      <v-icon
                        :color="control.layout.color"
                        class="dropdown-icon"
                      >
                        {{ control.layout.icon }}
                      </v-icon>
                      <span>{{ control.title }}</span>
                    </button>

                    <!-- Excel Download: ONLY SELECTED ITEMS -->
                    <vue-excel-xlsx
                      :columns="excelColumns"
                      :data="selectedRows"
                      :filename="excelFileName"
                      :sheetname="'Sheet1'"
                      v-if="selectedRows.length > 0 && !noExcelExport"
                    >
                      <button
                        :class="[this.$vuetify.theme.dark ? 'white--text' : '']"
                        class="dropdown-item"
                        tabindex="0"
                        type="button"
                        v-if="selectedRows.length > 0 && !noExcelExport"
                      >
                        <font-awesome-icon
                          class="dropdown-icon success--text"
                          :icon="['fal', 'file-excel']"
                        />
                        <span>Excel</span>
                      </button>
                    </vue-excel-xlsx>
                    <vue-excel-xlsx
                      :columns="excelColumns"
                      :data="filteredDatatableEntries"
                      :filename="excelFileName"
                      :sheetname="'Sheet1'"
                      v-if="selectedRows.length === 0 && !noExcelExport"
                    >
                      <button
                        :class="[this.$vuetify.theme.dark ? 'white--text' : '']"
                        class="dropdown-item"
                        tabindex="0"
                        type="button"
                        v-if="selectedRows.length === 0 && !noExcelExport"
                      >
                        <font-awesome-icon
                          class="dropdown-icon success--text"
                          :icon="['fal', 'file-excel']"
                        />
                        <span>Excel</span>
                      </button>
                    </vue-excel-xlsx>

                    <button
                      :disabled="selectedRows.length < 1"
                      @click="deleteEntries"
                      class="dropdown-item"
                      tabindex="0"
                      type="button"
                      v-if="showDeleteButtons /* && permissionDelete*/"
                    >
                      <v-icon
                        class="dropdown-icon"
                        color="error"
                      >
                        {{ $t('generic.lang_delete') }}
                      </v-icon>
                      <span>{{ $t('generic.lang_delete') }}</span>
                    </button>

                    <!--@start: custom buttons-->
                    <button
                      @click="customMenuButtonClick(btn)"
                      class="dropdown-item"
                      tabindex="0"
                      type="button"
                      :key="i"
                      v-for="(btn, i) in customRightMenuButtons"
                    >
                      <v-icon
                        class="dropdown-icon"
                        :color="btn.layout.iconColor"
                      >
                        {{ btn.layout.icon }}
                      </v-icon>
                      <span>{{ btn.title }}</span>
                    </button>
                    <!--@start: custom buttons-->
                  </div>
                </b-dropdown>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-title>

      <!-- MULTI SEARCH -->
      <v-container>
        <v-row>
          <v-col
            style="margin-top: -25px"
            v-for="header in filteredSearchableHeaders"
            :key="header.value"
            cols="12"
            :md="header.searchCol"
          >
            <v-text-field
              v-if="header.searchType === 'text'"
              @input="(text) => multiSearchColumn(text, header)"
              @focus="showTouchKeyboard"
              :data-layout="KEYBOARD.KEYSETS.NORMAL"
              :value="getMultiSearchColumnValue(header)"
              :label="header.text"
              dense
              height="20"
              style="margin-top: 15px; margin-bottom: -15px"
              outlined
            ></v-text-field>
            <v-select
              outlined
              style="margin-top: 15px; margin-bottom: -15px"
              v-if="
                header.searchType === 'multiselect' ||
                header.searchType === 'singleselect'
              "
              dense
              @input="(text) => multiSearchColumn(text, header)"
              :value="getMultiSearchColumnValue(header)"
              chips
              :items="searchPayload[header.value]"
              deletable-chips
              item-text="name"
              item-value="id"
              :label="header.text"
              :multiple="header.searchType === 'multiselect'"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table
        :footer-props="footerProps"
        :headers="filteredDatatableHeaders"
        :items="filteredDatatableEntries"
        :loading="loading"
        :multi-sort="enableMultiSort"
        :options.sync="pagination"
        :item-key="itemKey"
        :server-items-length="total"
        :show-select="showSelectCheckbox"
        :sort-by="filteredSortByHeaders"
        :sort-desc="filteredSortDirectionsHeaders"
        @click:row="clickRow"
        footer-props.items-per-page-text="Einträge pro Seite"
        v-model="selectedRows"
        :class="[this.showRightAndLeftBorder ? 'datatable-custom-border' : '']"
      >
        <!-- add headers slots -->
        <template
          v-for="_header in datatableHeaders.filter((elt) => !elt.hide)"
          v-slot:[`header.${_header.value}`]="{ header, index }"
        >
          <slot
            :name="`header.${header.value}`"
            v-bind:header="header"
            v-bind:value="header[_header.value]"
            v-bind:index="index"
          >
            <!-- default value -->
            {{ _header.text }}
          </slot>
        </template>

        <!-- add custom slots -->
        <template
          v-for="header in datatableHeaders.filter((elt) => !elt.hide)"
          v-slot:[`item.${header.value}`]="{ item, index }"
        >
          <div :key="header.value">
            <slot
              :name="`item.${header.value}`"
              v-bind:item="item"
              v-bind:value="item[header.value]"
              v-bind:index="index"
            >
              <!-- default value -->
              {{ item[header.value] }}
            </slot>
          </div>
        </template>

        <template v-slot:item.togglestatus="{ item }">
          <div v-if="showToggleStatus">
            <v-switch
              @change="toggleStatus(item)"
              v-model="item.togglestatus"
              value="1"
            />
          </div>
        </template>
        <template v-slot:item.crud="{ item }">
          <v-row
            :class="
              (showAddButtons &&
                showDeleteButtons &&
                showEditButtons &&
                showDisplayButtons) ||
              customButtons.length > 0
                ? 'min-width'
                : ''
            "
            align="center"
            justify="center"
          >
            <!-- Custom Buttons -->
            <v-btn
              :color="customButton.color"
              :icon="customButton.layout.icon"
              :key="customButton.text"
              :outlined="customButton.layout.outlined"
              :text="customButton.layout.text"
              @change="customButtonRowClick(customButton, item)"
              @click="customButtonRowClick(customButton, item)"
              class="mx-auto"
              v-for="customButton in customButtons"
            >
              <v-icon
                :class="customButton.text"
                v-if="customButton.layout.icon"
              ></v-icon>
              <span v-if="!customButton.layout.icon">{{
                customButton.text
              }}</span>
            </v-btn>

            <v-btn
              @click="addEntry(item)"
              class="mx-auto"
              color="success"
              icon
              text
              v-if="showAddButtons"
            >
              <v-icon>add</v-icon>
            </v-btn>

            <v-btn
              @click="scanEntry(item)"
              class="mx-auto"
              color="success"
              icon
              text
              v-if="showScanButtons"
            >
              <font-awesome-icon
                :icon="['fal', 'barcode-read']"
                class="mt-1"
                size="1x"
                style="font-size: 18px"
              />
            </v-btn>

            <v-btn
              @click="downloadExcel(item)"
              class="mx-auto"
              color="success"
              icon
              text
              v-if="showExcelButtons"
            >
              <font-awesome-icon
                :icon="['fal', 'file-excel']"
                class="mt-1"
                size="1x"
                style="font-size: 18px"
              />
            </v-btn>

            <v-btn
              @click="editEntry(item)"
              class="mx-auto"
              color="primary"
              icon
              text
              v-if="showEditButtons /*&& permissionEdit*/"
            >
              <v-icon>edit</v-icon>
            </v-btn>

            <v-btn
              @click="displayEntry(item)"
              class="mx-auto"
              color="primary"
              icon
              text
              v-if="showDisplayButtons"
            >
              <v-icon>remove_red_eye</v-icon>
            </v-btn>

            <v-btn
              @click="deleteEntry(item)"
              class="mx-auto"
              color="error"
              icon
              text
              v-if="showDeleteButtons /*&& permissionDelete*/"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <div ref="onScreenKeyboardDivDatatable">
      <vue-touch-keyboard
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidthExpanded"
        id="onScreenKeyboard"
        v-if="touchKeyboard.visible"
      />
    </div>

    <!-- html2pdf -->
    <div :v-show="false">
      <vue-html2pdf
        :filename="this.pdfFIleName"
        :paginate-elements-by-height="1400"
        :pdf-orientation="this.pdfOrientation"
        :pdf-quality="2"
        :preview-modal="true"
        :show-layout="false"
        pdf-format="a4"
        ref="html2Pdf"
      >
        <section
          :id="
            this.pdfOrientation === 'portrait'
              ? 'pdfcontainer'
              : 'pdfcontainer_land'
          "
          slot="pdf-content"
        >
          <section class="pdf-item">
            <h4 id="pdf-title">
              {{ this.pdfTitle || this.pdfFIleName }}
            </h4>

            <v-container>
              <v-simple-table id="pdftable">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        v-show="!head.hide"
                        :key="i"
                        v-for="(head, i) in datatableHeaders"
                      >
                        {{ head.text }}
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="selectedRows.length > 0">
                    <tr
                      :key="i"
                      v-for="(elt, i) in selectedRows"
                    >
                      <td
                        v-show="!isHiddenColumn(j)"
                        :key="j"
                        v-for="(row, j) in elt"
                      >
                        {{ row }}
                      </td>
                    </tr>
                  </tbody>

                  <tbody v-else>
                    <tr
                      :key="i"
                      v-for="(elt, i) in dataToShow"
                    >
                      <td
                        v-show="!isHiddenColumn(j)"
                        :key="j"
                        v-for="(row, j) in elt"
                      >
                        {{ row }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-container>
          </section>
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBarcodeRead, faFileExcel } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Events } from '@/plugins/events';
import { mapState } from 'vuex';

import VueHtml2pdf from 'vue-html2pdf';
import { KEYBOARD } from '@/config';

library.add(faFileExcel, faBarcodeRead);

export default {
  name: 'Datatable',

  components: {
    'font-awesome-icon': FontAwesomeIcon,
    'vue-html2pdf': VueHtml2pdf,
  },

  props: {
    hideOptionsMenu: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    datatableHeaders: {
      type: Array,
      default: () => [],
    },
    excelColumns: Array,
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    noExcelExport: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    excelFileName: {
      type: String,
      default: 'filename',
    },
    apiEndpoint: String,
    additionalGetParams: {
      type: Array,
      default: function () {
        return [];
      },
    },
    showSelectCheckbox: {
      type: Boolean,
      default: true,
    },
    showEditButtons: {
      type: Boolean,
      default: false,
    },
    showDeleteButtons: {
      type: Boolean,
      default: false,
    },
    permissionDelete: {
      type: Boolean,
      default: false,
    },
    permissionEdit: {
      type: Boolean,
      default: false,
    },
    showDisplayButtons: {
      type: Boolean,
      default: false,
    },
    showAddButtons: {
      // when we have list of group and we need to items inside each group
      type: Boolean,
      default: false,
    },
    customButtons: {
      /*[

            {
            title: value,
            evant: value,
            layout: {
            icon: value
            }
            }]*/
      type: Array,
      default: () => {
        return [];
      },
    },
    customRightMenuButtons: {
      /*[

            {
            title: value,
            evant: value,
            layout: {
            icon: value
            }
            }]*/
      type: Array,
      default: () => {
        return [];
      },
    },
    // add the forceEnable attribute and set it to true to handle custom events that doesn't require selected rows
    customControlButtons: {
      type: Array,
      default: () => {
        return [];
      },
    },
    elevation: {
      // sometimes we need to remove the shadow of datatable so we can use this props to control it
      type: Number,
      default: 2,
    },
    showPdf: {
      type: Boolean,
      default: false,
    },
    showSelectedPdf: {
      type: Boolean,
      default: false,
    },
    pdfSelectedLoading: {
      type: Boolean,
      default: false,
    },
    showScanButtons: {
      type: Boolean,
      default: false,
    },
    showExcelButtons: {
      type: Boolean,
      default: false,
    },
    showToggleStatus: {
      type: Boolean,
      default: false,
    },
    pdfTitle: String,
    pdfFIleName: {
      type: String,
      default: 'pdffile',
    },
    pdfOrientation: {
      type: String,
      default: 'portrait',
    },
    enableMultiSort: {
      type: Boolean,
      default: false,
    },
    searchPayload: Object,
    datatableAttachSearch: {
      type: Array,
      default: function () {
        return [];
      },
    },
    resetSelected: {
      type: Boolean,
      default: true,
    },
    resetSearch: {
      type: Boolean,
      default: false,
    },
    showSearchField: {
      type: Boolean,
      default: true,
    },
    autoFillHeaders: {
      type: Boolean,
      default: false,
    },
    showRightAndLeftBorder: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: this.datatableHeaders,
      dataToShow: [],
      search: '',
      awaitingSearch: null,
      awaitingMultiSearch: null,
      total: 0,
      loading: false,
      selectedRows: [],
      pagination: {},
      footerProps: { 'items-per-page-options': [15, 30, 50, 100] },
      rowsPerPageItems: [10, 20, 50, 100],
      multiSearchColumns: {},
      lockWatch: true,

      // --- KEYBOARD ---
      KEYBOARD,
      touchKeyboard: {
        visible: false,
        layout: 'normal',
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false,
        },
      },
      // ----------------
    };
  },

  computed: {
    ...mapState(['touchkeyboard']),
    /**
     * @return {(function(Number): boolean)}
     */
    isHiddenColumn() {
      return (column) => {
        //let's find this column in the dataTable header
        if (
          Array.isArray(this.datatableHeaders) &&
          this.datatableHeaders.length > 0
        ) {
          let search = this.datatableHeaders[column];
          if (search && typeof search === 'object') return search.hide === true;
        }
        return false;
      };
    },
    filteredDatatableHeaders: function () {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty('hide')) {
          if (header.hide) {
            return;
          }
        }

        headers.push(header);
      });

      //ADD CRUD HEADER
      if (
        this.showEditButtons ||
        this.showDeleteButtons ||
        this.showDisplayButtons ||
        (this.customButtons && this.customButtons.length > 0)
      ) {
        headers.push({
          text: '',
          value: 'crud',
          sortable: false,
          width: '230',
          align: 'end',
        });
      }

      return headers;
    },
    filteredDatatableEntries: function () {
      let dataToRender = [];

      this.dataToShow.forEach((data) => {
        let row = {};

        this.datatableHeaders.forEach((header, index) => {
          if (!(header.hidden && header.hidden == true)) {
            row[header.value] = data[index];
          }
        });

        dataToRender.push(row);
      });

      return dataToRender;
    },
    filteredSortByHeaders() {
      let headers = [];
      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty('sort')) headers.push(header.value);
      });
      return headers;
    },
    filteredSortDirectionsHeaders() {
      let headers = [];
      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty('sort')) headers.push(header.sort === 'desc');
      });

      return headers;
    },
    filteredSearchableHeaders() {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty('searchable'))
          if (header.searchable) {
            if (!header.hasOwnProperty('searchType'))
              header.searchType = 'text';

            header.attached = false;

            headers.push(header);
          }
      });

      //ATTACH ATTACHED-SEARCH HEADERS
      this.datatableAttachSearch.forEach((search) => {
        search.attached = true;

        headers.push(search);
      });

      return headers;
    },
  },

  created() {
    //LOCK WATCHER
    this.lockWatch = true;

    //GET SAVED STATE
    let savedState = this.$store.getters['datatable/getSavedState'](
      this.$route.name
    );

    if (savedState !== null) {
      this.multiSearchColumns = savedState.multiSearchColumns;
      this.pagination = savedState.pagination;
      if (!this.resetSearch) {
        this.search = savedState.search;
      }

      this.$nextTick(() => {
        this.lockWatch = false;
      });
    } else {
      this.lockWatch = false;
    }
  },

  mounted() {
    this.getDataFromApi();

    //LISTEN FOR OUTSIDE CLICK
    document.addEventListener('click', this.dismissOnScreenKeyboard);
  },

  destroyed() {
    document.removeEventListener('click', this.dismissOnScreenKeyboard);
  },

  watch: {
    search: function () {
      if (this.lockWatch) return;

      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 1000);
    },
    pagination: {
      handler() {
        if (this.lockWatch) return;

        this.getDataFromApi();
      },
      deep: true,
    },
    multiSearchColumns: {
      handler() {
        if (this.lockWatch) return;

        clearTimeout(this.awaitingMultiSearch);

        this.awaitingMultiSearch = setTimeout(() => {
          this.getDataFromApi();
        }, 1000);
      },
      deep: true,
    },
    selectedRows: function () {
      this.$emit('changeSelectedRows', this.selectedRows);
    },
  },

  methods: {
    // ------------ TOUCH KEYBOARD -------------
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled) return;

      this.touchKeyboard.input = e.target;
      this.touchKeyboard.layout = e.target.dataset.layout;

      if (!this.touchKeyboard.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboard.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboard() {
      if (this.touchKeyboard.visible) {
        this.touchKeyboard.visible = false;
      }
    },
    dismissOnScreenKeyboard: function (e) {
      if (
        this.touchkeyboard.settings.enabled &&
        this.$refs.onScreenKeyboardDivDatatable
      ) {
        if (!this.$refs.onScreenKeyboardDivDatatable.contains(e.target)) {
          if (e.target.tagName !== 'INPUT') {
            this.hideTouchKeyboard();
          }
        }
      }
    },
    // -----------------------------------------
    multiSearchColumn(input, header) {
      this.$set(this.multiSearchColumns, header.value, input);
    },
    getMultiSearchColumnValue(header) {
      if (!this.multiSearchColumns.hasOwnProperty(header.value)) return '';

      return this.multiSearchColumns[header.value];
    },
    getDataTableHeaders() {
      return this.headers;
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    editEntry(entry) {
      this.$emit('editEntry', entry);
    },
    deleteEntry(entry) {
      this.$emit('deleteEntry', entry);
    },
    deleteEntries() {
      let ids = [];

      this.selectedRows.forEach((row) => {
        ids.push(row[this.itemKey]);
      });

      this.$emit('deleteEntries', ids);
    },
    exportSelectedToPdf() {
      let ids = [];

      this.selectedRows.forEach((row) => {
        ids.push(row.id);
      });

      this.$emit('downloadSelectedPdf', ids);
    },
    clickRow(entry) {
      if (
        !this.showAddButtons &&
        !this.showEditButtons &&
        !this.showExcelButtons &&
        !this.showDeleteButtons &&
        this.customButtons.length === 0
      )
        this.displayEntry(entry);

      this.$emit('clickRow', entry);
    },
    toggleStatus(entry) {
      this.$emit('toggleStatus', entry);
    },
    displayEntry(entry) {
      this.$emit('displayEntry', entry);
    },
    addEntry(entry) {
      this.$emit('addEntry', entry);
    },
    scanEntry(entry) {
      this.$emit('scanEntry', entry);
    },
    downloadExcel(entry) {
      this.$emit('downloadExcel', entry);
    },
    customButtonClick(customButton) {
      this.$emit(customButton.event, this.selectedRows);
    },
    customControl(handler) {
      let ids = [];

      this.selectedRows.forEach((row) => {
        ids.push(row.id);
      });
      this.$emit(handler, ids);
    },
    customButtonRowClick(customButton, entry) {
      if (customButton.eventRow !== '')
        this.$emit(customButton.eventRow, entry);
      else if (customButton.changeEvent !== '')
        this.$emit(customButton.changeEvent, entry);
    },
    /**
     * emit custom menu buttons events
     * @param {Object} button
     */
    customMenuButtonClick(button) {
      if (this.isValidObject(button)) this.$emit(button.event);
    },
    resetSelectedRows() {
      this.selectedRows = [];
      //this.$emit("changeSelectedRows", []);
    },
    getDataFromApi(ExtraParams = null) {
      this.loading = true;
      if (this.resetSelected) {
        this.selectedRows = [];
      }

      let params = {
        sEcho: 1,
        iDisplayStart:
          this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage,
      };

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        let tempSearch = this.search;
        if(this.search.length>3 && (this.search.substring(0, 2).toLowerCase()==='v$' || this.search.substring(0, 3).toLowerCase()==='gf$')){
          tempSearch = this.search.replace('v$','').replace('V$','').replace('gf$','').replace('GF$','');
        }
        params.sSearch = tempSearch;
      }

      if (this.pagination.sortBy) {
        //LOOP ALL SORT COLS
        this.pagination.sortBy.forEach((sortCol, index) => {
          //FIND COL. INDEX
          let tableIndex = this.datatableHeaders.findIndex(
            (header) => header.value === this.pagination.sortBy[index]
          );

          if (tableIndex < 0) {
            tableIndex = 0;
          }

          params['iSortCol_' + index] = tableIndex;
          params['bSortable_' + tableIndex] = 'true';
          params['sSortDir_' + index] = this.pagination.sortDesc[index]
            ? 'desc'
            : 'asc';
        });

        params['iSortingCols'] = this.pagination.sortBy.length;
      }

      //MULTI SEARCH
      for (let headerValue in this.multiSearchColumns) {
        if (!this.multiSearchColumns.hasOwnProperty(headerValue)) continue;

        let multiSearch = this.filteredSearchableHeaders.find(
          (header) => header.value === headerValue
        );
        let tableIndex = this.datatableHeaders.findIndex(
          (header) => header.value === headerValue
        );

        if (multiSearch?.attached) {
          params['attached_' + headerValue] =
            this.multiSearchColumns[headerValue];
        } else {
          params['bSearchable_' + tableIndex] = 'true';
          params['sSearch_' + tableIndex] =
            this.multiSearchColumns[headerValue];
        }
      }

      //add data in our get request
      if (this.data) {
        for (const p in this.data) {
          params[p] = this.data[p];
        }
      }

      //add some extra parameters when we want to reload data instead of using only data binding
      if (ExtraParams && typeof ExtraParams === 'object')
        Object.assign(params, ExtraParams);

      //ADD CUSTOM GET PARAMS:
      if (this.additionalGetParams.length > 0) {
        params[this.additionalGetParams[0]] = this.additionalGetParams[1];
      }

      this.axios
        .get(this.apiEndpoint, {
          params: params,
        })
        .then((res) => {
          this.total = parseInt(res.data.iTotalDisplayRecords);
          this.dataToShow = [];
          this.dataToShow = res.data.aaData;

          // auto fill headers if not provided
          if (
            !this.headers?.length &&
            this.autoFillHeaders &&
            this.dataToShow?.length > 0
          ) {
            const firstRowColumns = this.dataToShow[0];
            for (let column = 0; column < firstRowColumns?.length; column++) {
              this.headers.push({
                text: column,
                align: 'center',
                value: column,
                sortable: false,
                width: 200,
              });
            }

            this.$emit('headersUpdated', this.headers);
          }

          //SAVE STATE
          this.$store.dispatch('datatable/saveState', {
            routeName: this.$route.name,
            state: {
              pagination: this.pagination,
              multiSearchColumns: this.multiSearchColumns,
              search: this.search,
            },
          });

          this.loading = false;
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            color: 'error',
            message: err.message,
          });

          this.dataToShow = [];
        });
    },
  },
};
</script>

<style scoped>
.multiSearchInput .v-input {
  min-height: 20px !important;
}

.min-width {
  min-width: 300px !important;
}

#pdf-title {
  background-color: grey !important;
  text-align: center !important;
  height: 40px !important;
  padding: 5px !important;
  color: white;
  background-color: #1e3c72;
}

#pdftable {
  width: 100% !important;
}

#pdfcontainer {
  width: 102% !important;
  margin-left: -12px !important;
  padding: 5px !important;
}

#pdfcontainer_land {
  width: 142.5% !important;
  margin-left: -12px !important;
  padding: 5px !important;
}

@media (max-width: 767.98px) {
  .card-header.card-header-tab .card-header-title {
    width: 100%;
  }
}
</style>

<style>
.datatable-custom-border td,
.datatable-custom-border th {
  border-right: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-left: 1px solid rgba(0, 0, 0, 0.125) !important;
}
</style>
